

.sp-tab {
    display: flex;
    flex-direction: row;
    padding: 0px;
    width: 282px;
    height: 24px;
    margin-bottom: 32px;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "General Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      padding: 12px 0;
      line-height: 144%;
      text-align: center;
      background-color: #ffffff;
      border: 1px solid $primaryTextColor;
      color: $primaryTextColor;
      cursor: pointer;
      &:first-child {
        width: 92px;
        border-radius: 26px 0px 0px 26px;
        border-right: none !important;
      }
      &:nth-child(2) {
        padding: 12px 16px;
        border-right: none !important;
      }
      &:last-child {
        width: 91px;
        border-radius: 0px 26px 26px 0px;
      }
      &:hover {
        background-color: $hoverColor;
      }
    }
    button.active {
      background-color: $primaryTextColor;
      color: #ffffff;
    }
  }