@import "abstracts";
@import "mixins.scss";
@import "components";
@import "typography";
@import "custom.scss";
@import "common.scss";

body {
  margin: 0;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  // font-size: 0.97vw !important;
  /* line-height: 180%; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: fit-content !important;
  width: 100%;
  margin: 0;
  padding: 0;
}

.title {
  font-family: "Cabinet Grotesk Bold";
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  line-height: 79px;
  color: #2c264b;
}

.welcome-img {
  width: "100%";
}

.line_break {
  &__sm {
    height: 20px;
  }
  &__lg {
    height: 40px;
  }
}
