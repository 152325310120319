.filter-modal {
  background: #ffffff;
  height: fit-content;
  width: 260px;
  height: fit-content;
  border-radius: 8px !important;
  padding: 16px 0;
  .filter-modal-container {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    width: 100%;
    gap: 24px;
    .filter-modal-inner {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-self: center;
      justify-self: center;
      width: 90%;
      p {
        margin: 0;
        font-family: "General Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 144%;
        color: #000000;
      }
      button {
        width: 100%;
        height: 36px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 8px 12px;
        background-color: $sapaGrey;
        border: none;
        outline: none;
        border-radius: 26px;
        font-family: "General Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 144%;
        color: #b1b1b1;
        &:focus {
          border: 1px solid $primaryTextColor;
          color: $sapaBlack;
        }
        img {
          width: 12px;
          height: 17px;
        }
      }
    }
    .filter-modal-btn__container {
      padding: 0 14px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      button {
        height: 36px !important;
        span {
          font-size: 14px !important;
        }
      }
      .filter-modal-btn__fill {
        padding: 0 !important;
        width: 120px !important;
        font-weight: 500;
        font-size: 11px !important;
        line-height: 144%;
      }
      .filter-modal-btn__transparent {
        width: 94px !important;
      }
    }
  }
}
.filter-transaction-items {
  width: 153px !important;
  border-radius: 0 !important;
  p {
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 144%;
    color: $sapaBlack;
    padding: 0 !important;
    margin: 0 !important;
  }
}
.selected {
  color: $sapaBlack !important;
}
