.Security {
  background-color: $bgColor;
  .sapa-security__container {
    margin-top: 24px;
    border-radius: 0 !important;
    border: none !important;
    height: fit-content !important;
    .ant-card-bordered {
      border: none !important;
    }
    .ant-card-body {
      padding: 0 !important;
    }
  }
  p {
    margin: 0;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 144%;
    color: #121020;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 32px;
    div {
      margin: 0 !important;
    }
    .ant-form-item-row {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .ant-form-item-label {
      padding: 0 !important;
    }
    label {
      font-family: "General Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 144%;
      color: #000000;
    }
  }
  .sapa-security__input input {
    width: 90%;
    height: 40px;
    padding: 16px 24px;
    background: $sapaGrey !important;
    border-radius: 26px;
    &::placeholder {
      font-family: "General Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 144%;

      color: #2c264b;
    }
  }
  //   .sapa-security__inputWrapper {
  //     margin-bottom: 48px;
  //   }
  .sapa-security__password input {
    width: 92.5%;
    height: 40px;
    padding: 16px 24px;
    background: $sapaGrey !important;
    border-radius: 26px;
  }
  .ant-input-affix-wrapper {
    height: 48px !important;
  }
  button {
    width: 380px !important;
    height: 52px !important;
  }
  //   .ant-input-affix-wrapper-status-error {
  //     &:focus {
  //       border: 30px solid red !important;
  //     }
  //   }
  .ant-form-item-feedback-icon-error {
    display: none !important;
  }
  .anticon-eye-invisible {
    margin: 0 !important;
  }
  //   svg {
  //     width: 16.666873931884766px !important;
  //   }
}
