.sapa-signin__container {
  background: #fefefe;
  height: fit-content !important;
  background: #fefefe;
  .sapa-signin__footer {
    width: 100%;
    height: auto;
    padding: 0;
    background: #fefefe;
    margin-bottom: 57px;
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 80%;
      margin: 0 auto;
      a {
        font-size: 11px !important;
        margin: 0;
        font-family: "General Sans";
        font-style: normal;
        font-weight: 400;
        line-height: 144%;
        text-align: right;
        color: #000000;
      }
      a:nth-child(2) {
        text-decoration: underline;
      }
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .sapa-signin__inner {
    width: 85%;
    margin: 80px auto 0 auto;
    div {
      position: relative;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .sapa-signin__circle {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13px;
    position: absolute;
    left: 0;
    top: 25%;

    span {
      position: relative;
      width: 24px;
      height: 24px;
      border-radius: 60%;
      background-color: rgba(151,71,255,.1);
    }
    ion-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 10.77px;
      text-align: center;
      color: $primaryTextColor;
    }
    p {
      font-family: "General Sans";
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      color: $primaryTextColor;
      text-align: center;
      margin: 0;
      padding: 0;
      line-height: 22px;
    }
    p:hover {
      color: #9747ff;
    }
    // span:hover {
    //     background-color: #6B32B5;
    //     color: $white;
    // }
  }
  p {
    font-family: "Cabinet Grotesk";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: $sapaBlack;
    margin-top: 96.75px;
    margin-bottom: 40px;
  }
  .sapa-signin__input input {
    width: 90%;
    height: 40px;
    padding: 16px 24px;
    background: $sapaGrey !important;
    border-radius: 26px;
    &::placeholder {
      font-family: "General Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 144%;

      color: #2c264b;
    }
  }
  .sapa-signin__input {
    height: 48px !important;
  }
  // .sapa-signin__inputWrapper {
  //   margin-bottom: 48px;
  // }
  .sapa-signin__password input {
    width: 90%;
    height: 40px;
    padding: 16px 24px;
    background: $sapaGrey !important;
    border-radius: 26px;
  }
  .sapa-signin__password {
    height: 48px !important;
  }
  label {
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 144%;
    width: 380px;
    color: #000000;
    margin-bottom: 8px;
  }
  .sapa-signin__button {
    width: 199px;
    height: 52px;
  }
  .sapa-signin__button button {
    border-radius: 26px !important;
  }
  .sapa-signin__footerLinks {
    display: flex;
    gap: 32px;
    width: fit-content;
  }
  .sapa-signin__footerIcons {
    display: flex;
    gap: 10.88px;
  }
}
