.Alerts {
  background-color: $bgColor;
  .sapa-alerts__container {
    margin-top: 24px;
    border-radius: 0 !important;
    border: none !important;
    height: fit-content !important;
    .ant-card-bordered {
      border: none !important;
    }
    .ant-card-body {
      padding: 0 !important;
    }
    td:nth-child(2) {
      padding-right: 50px !important;
    }
  }
}
