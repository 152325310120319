.layout {
  background: $bgColor;
  width: 100vw;
  height: 100% !important;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
  &__header {
    background: $white !important;
    padding: 10px;
    height: auto !important;
    line-height: 50px !important;
  }
  &__logo {
    width: 70px;
    object-fit: contain;
  }
  &__nav {
    display: flex;
    justify-content: space-between;
    padding: 0 50px 0 50px;
    // background: red;
  }
  &__login_text {
    padding-inline: 30px;
    cursor: pointer;
  }
  &__button {
    height: 35px;
  }
  &__child {
    margin-left: 0px !important;
  }
}
