.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Grey with 50% transparency
  z-index: 999; // Make sure the overlay appears on top of other elements
}

.Filterbox {
  margin-bottom: 32px !important;
  background-color: white;
  .sapa-filterbox__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .sapa-filterbox__header_inner {
      display: flex;
      column-gap: 24px;
    }
    .sapa-filterbox__header_container {
      display: flex;
      flex-direction: column;
      gap: 12px;
      label {
        font-family: "General Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 144%;
        color: #000000;
      }
      .sapa-filterbox-filter {
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        height: 40.28px;
        padding: 0 !important;
        padding: 0 18px !important;
        // padding: 0 0 0 20.1401px;
        background: $sapaGrey !important;
        border: 0.83917px solid #9747ff;
        border-radius: 21.8184px;
        font-family: "General Sans";
        text-transform: capitalize;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 144%;
        color: $sapaBlack;
        outline: none !important;
        cursor: pointer;
        span {
          height: 16.78px;
          width: 16.78px;
          margin-left: 6.71px !important;
          img {
            height: 16.78px;
            width: 16.78px;
          }
        }
      }
    }
  }
  .sapa-search-input {
    width: 362px !important;
    border: none !important;
    outline: none !important;
    padding-left: 20px !important;
    &:focus {
      border: none !important;
      outline: none !important;
    }
    .ant-input-prefix {
      height: fit-content !important;
      width: fit-content !important;
      margin: 0 !important;
      margin-right: 6.71px !important;
      display: flex;
      justify-self: center !important;
      align-self: center !important;
      i {
        height: 16.78px !important;
        img {
          height: 16.78px;
          width: 16.78px;
        }
      }
    }
    input {
      width: 80% !important;
      border: none !important;
      outline: none !important;
      border-radius: 26px !important;
      background-color: #f5f5f5 !important;

      &::placeholder {
        font-family: "General Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 144%;
        color: #b1b1b1;
      }
    }
  }

  .search-icon {
    position: absolute;
    left: 31.5%;
    top: 55.5% !important;
    bottom: 0;
    height: fit-content;
  }
  //   .filter-icon {
  //     position: absolute;
  //     left: 22.8%;
  //     height: fit-content;
  //     width: fit-content;
  //     top: 55.3% !important;
  //   }
  .filter-cloud-btn {
    width: 148px !important;
    .filter-primary_button {
      height: 40px !important;
      span {
        font-size: 14px !important;
        line-height: 144%;
        img {
          width: 10px !important;
        }
      }
    }
  }
}
