.services-modal {
    padding: 0 40px !important;
.services-modal-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  min-height: 65vh;
  margin-top: 40px;
  p {
    margin: 0;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 144%;
    color: #121020;
  }
  .services-modal-selector {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border: 1px solid #f5f5f5;
    border-radius: 12px;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 144%;
    color: #121020;
    cursor: pointer;
    &:hover {
      border-color: $primaryTextColor;
      color: $primaryTextColor;
    }
  }
  .services-modal-selector.selected {
    border-color: $primaryTextColor;
    font-weight: 600;
  }
}

.services-modal-btn__container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    height: 40px !important;
    margin-top: 40px;
    button {
      height: 40px !important;
      &:first-child {
        width: 138px !important;
      }
      &:last-child {
        width: 95px !important;
      }
      span {
        font-size: 14px !important;
      }
    }
  }
}