.homepage {
  &__title {
  }
  &__img {
    width: 90%;
  }
  &__container {
    margin: 90px;
    &__row-one {
      padding: 25px;
    }
    &__row-two {
      display: flex;
      align-items: flex-end;
    }
  }
  &_content__img_wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
}

.service_tile {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
  }
}
