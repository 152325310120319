.overview-table-input {
  width: 120px !important;
  height: 32px !important;
  background: #f5f5f5 !important;
  margin: 0 !important;
  border: none !important;
  &::placeholder {
    font-size: 11px !important;
  }
}
.overview-table-btn {
  width: 57px !important;
  height: 24px !important;
  span {
    font-size: 11px !important;
  }
}
