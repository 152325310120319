// span,
// p,
// label {
//   font-family: "General Sans";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 14px;
//   // font-size: 0.97vw;
//   line-height: 180%;
//   // color: #9a96b8;
//   color: $textColor;
// }

h2 {
  font-family: "General Sans Bold";
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  line-height: 79px;
  color: #2c264b;
}

h3 {
  font-family: "Cabinet Grotesk Bold";
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 27px;
  color: #121020;
}

h4 {
  font-family: "Cabinet Grotesk Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #121020;
}
