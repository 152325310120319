.Sapa__admin_bg{
  background-color: $bgColor;
  max-width: 1440px;
  height: 100% !important;
}

.centered_layout {
  margin: 0 auto;
}

.centered_text {
  text-align: center;
}

.blur {
  filter: blur(4px) !important;
  -webkit-filter: blur(4px) !important;
}

// .ant-modal {
//   > &-close {
//     width: 32px !important;
//     height: 32px !important;
//     border-radius: 16px !important;
//     background: #f5f5f5;
//   }
// }

.ant-modal .ant-modal-close {
  width: 32px !important;
  height: 32px !important;
  border-radius: 16px !important;
  background: #f5f5f5;
}
