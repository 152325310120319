.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 67px;
  background-color: $bgColor !important;
  padding-inline: 0 !important;
  .sapa-header__title {
    display: flex;
    gap: 20px;
    align-items: center;
    width: fit-content;
    span {
      font-family: "General Sans";
      font-style: normal;
      font-weight: 1000;
      font-size: 18px;
      line-height: 144%;
      color: #181529 !important;
      text-align: center;
    }
  }
  .sapa-header__profile {
    width: 149px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      background: #9747ff;
      font-family: "General sans";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: $bgColor;
    }
    img {
      width: 13px;
    }
  }
}
