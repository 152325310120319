.table-action-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: none;
  outline: none;
  border-radius: 60%;
  background-color: rgba(151,71,255,.1);
  cursor: pointer;
  padding: 0;
  &:hover {
    background-color: rgb(232, 232, 255);
  }
  & img {
    width: 18px;
    height: 18px;
  }
}
