.Services {
  background-color: $bgColor;
  .sapa-services__tab {
    display: flex;
    flex-direction: row;
    padding: 0px;
    width: 183px;
    height: 24px;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "General Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      padding: 12px 0;
      line-height: 144%;
      text-align: center;
      background-color: #ffffff;
      border: 1px solid $primaryTextColor;
      color: $primaryTextColor;
      cursor: pointer;
      &:first-child {
        width: 92px;
        border-radius: 26px 0px 0px 26px;
      }
      &:last-child {
        width: 91px;
        border-radius: 0px 26px 26px 0px;
      }
      &:hover {
        background-color: $hoverColor;
      }
    }
    button.active {
      background-color: $primaryTextColor;
      color: #ffffff;
    }
  }
  .sapa-services__container {
    margin-top: 32px;
    // margin-bottom: 47.72px !important;
    border-radius: 0 !important;
    border: none !important;
    height: fit-content !important;
    .ant-card-bordered {
      border: none !important;
    }
    .ant-card-body {
      padding: 0 !important;
    }
  }
  .sapa-services-table__showbtn_container {
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
    margin-top: 32px;
    margin-bottom: 24px;
    button {
      font-family: "General Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 144%;
      text-align: center;
      color: $primaryTextColor;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px;
      width: 87px;
      height: 24px;
      background: $hoverColor;
      border-radius: 26px;
      outline: none;
      border: none;
      cursor: pointer;
      &:hover {
        background-color: $hoverColorlite;
      }
    }
  }
  .Query-logs {
    margin-top: 32px;
    .query-logs__filter-box {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .query-logs__duration {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 13.43px;
        button {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 158px;
          height: 40.28px;
          padding: 0 20.1401px;
          background: #f5f5f5;
          border-radius: 21.8184px;
          outline: none;
          border: none;
          cursor: pointer;
          font-family: "General Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 144%;
          color: #2c264b;
          &:focus {
            border: 1px solid $primaryTextColor;
          }
          img {
            height: 5.944121837615967px;
            width: 10.83928108215332px;
          }
        }
        p {
          margin: 0;
        }
      }
      .query-logs__btn-container {
        display: flex;
        gap: 24px;
        width: fit-content;
        height: 40px;
        button {
          height: 40px !important;
          span {
            font-size: 14px !important;
          }
          &:first-child {
            min-width: 123px;
            width: 123px;
          }
          &:last-child {
            min-width: 137px;
            min-width: 137px;
          }
        }
      }
    }
    .queries {
      padding: 24px;
      margin-top: 32px;
      height: 721.29px;
      background: #fafafa;
      border-radius: 12px;
      span {
        max-width: 100px !important;
      }
    }
  }
}
