.Users {
  background-color: $bgColor;
  .sapa-users__container {
    margin-top: 24px;
    margin-bottom: 47.72px !important;
    border-radius: 0 !important;
    border: none !important;
    height: fit-content !important;
    .ant-card-bordered {
      border: none !important;
    }
    .ant-card-body {
      padding: 0 !important;
    }
  }

  .sapa-users-table__showbtn_container {
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
    margin-top: 32px;
    margin-bottom: 24px;
    button {
      font-family: "General Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 144%;
      text-align: center;
      color: #9747ff;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px;
      width: 87px;
      height: 24px;
      background: rgba(151,71,255,.1);
      border-radius: 26px;
      outline: none;
      border: none;
      cursor: pointer;
      &:hover {
        background-color: #e6d8f7;
      }
    }
  }
  .users-overview {
    .users-overview__container {
      border-radius: 0 !important;
      border: none !important;
      height: fit-content !important;
      .ant-card-bordered {
        border: none !important;
      }
      .ant-card-body {
        padding: 0 !important;
      }
      
      .users-overview__dashboard-card-container {
        display: flex;
        justify-content: flex-start;
        gap: 24px;
        margin-bottom: 32px;
        .dashboard-card__container {
          width: 25% !important;
          .ant-card-bordered {
            border: 1px solid rgba(151,71,255,.1) !important;
            box-shadow: 0px 2px 40px rgba(177, 177, 177, 0.05);
            border-radius: 12px;
          }
          .ant-card-body {
            padding: 20px !important;
          }
          .dashboard-card__container_inner {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        }
      }
    }
    .users-overview__lower-section  {
      display: flex;
      flex-direction: column;
      gap: 32px;
      div {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 380px;
        input {
          width: 100%;
          height: 48px !important;
          margin: 0 !important;
          padding: 16px 24px;
          background: #f5f5f5;
          border-radius: 26px;
          border: none;
          outline: none;
          &:hover {
            border: 1px solid $primaryTextColor;
          }
          &:active {
            border: 1px solid $primaryTextColor;
          }
          &::placeholder {
            font-family: "General Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 144%;
            color: #2c264b;
          }
        }
        p {
          font-family: "General Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 144%;
          color: #000000;
          margin: 0;
        }
        &:first-child {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 20px;
          p {
            font-family: "General Sans";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 144%;
            text-align: center;
            color: #121020;
            margin: 0;
          }
          button {
            padding: 6px 16.8px;
          }
        }
      }
    }
  }
}

.span-container {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  gap: 8px !important;
  span {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 50%;
  }
}

.users-overview {
  form {
    display: flex;
    flex-direction: column;
    row-gap: 32px;

    p {
      text-align: left !important;
    }
  }
}
