
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: auto;
}


.sp-select {
    display: block;

    input {
        background-color: #F5F5F5 !important;
        border: none;
        border-radius: 32px;
        padding: 8px 0px;
        
    }

    &:focus {
        outline: none;
        border: 1px solid #9747ff;
        box-shadow: none;
    }

    &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        position: relative;
        background-color: #F5F5F5;
        border: none;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        height: auto;
    }

    .ant-select-selector {
        width: 100% !important;
        background: #f5f5f5 !important;
        border-radius: 26px !important;
        padding: 8px 24px !important;
    }
}


