.administrators-modal {
  padding: 0 40px !important;
  .administrators-modal-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    height: 778px;
    margin-top: 40px;

    div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 16px;
      width: 100%;
    }
    p {
      margin: 0;
      font-family: "General Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 144%;
      color: #121020;
    }
    input {
      padding: 0 24px;
      height: 48px;
      background: #f5f5f5;
      border-radius: 26px;
      outline: none;
      border: none;
      &::placeholder {
        font-family: "General Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 144%;
        color: #121020;
      }
      &:hover {
        border: 1px solid #9747ff;
      }
      &:active {
        border: 1px solid #9747ff;
      }
    }
  }

  .administrators-modal-btn__container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    height: 40px !important;
    margin-top: 40px;
    button {
      height: 40px !important;
      &:first-child {
        width: 120px !important;
      }
      &:last-child {
        width: 153px !important;
      }
      span {
        font-size: 14px !important;
      }
    }
  }
}
